<template>
  <v-dialog
    :value="dialogVisible"
    width="440"
    @input="$emit('toggle')"
  >
    <template v-slot:activator="{ on: dialog }">
      <v-tooltip
        top
      >
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            v-if="$vuetify.breakpoint.smAndUp"
            icon
            small
            v-on="{ ...tooltip, ...dialog }"
          >
            <v-icon
              style="font-size: 17px;"
              color="onyx"
            >
              mdi-book-open-page-variant
            </v-icon>
          </v-btn>
        </template>
        View element details
      </v-tooltip>
    </template>
    <v-card>
      <v-card-title class="d-block">
        <div
          class="d-flex justify-space-between"
          :class="$vuetify.breakpoint.xsOnly ? 'flex-column align-start' : 'flex-row align-center'"
        >
          <component
            :is="element.webLink ? 'a' : 'div'"
            :href="element.webLink"
            target="_blank"
            class="title d-flex align-center mb-3"
          >
            <BrandLogo
              v-if="element.manufacturer"
              :brand="element.manufacturer"
              size="30"
              class="mr-3"
              style="background: yellow;"
            />
            {{ element.name }}
            <v-icon
              v-if="element.webLink"
              right
              color="shamrock"
              style="font-size: 20px;"
            >
              mdi-open-in-new
            </v-icon>
          </component>
          <v-btn
            v-if="element.epd"
            :href="element.epd"
            class="subtitle-2 metal--text mb-3"
            :style="{ color: average > 0 ? '#dc0e15' : 'var(--v-shamrock-base)' }"
            outlined
          >
            Download EPD
            <v-icon right>
              mdi-download
            </v-icon>
          </v-btn>
        </div>
        <div
          v-if="average && average !== 0"
          class="subtitle-2"
          :style="{ color: average > 0 ? '#dc0e15' : 'var(--v-shamrock-base)' }"
        >
          Climate
          {{ average > 0 ? 'negative' : 'positive' }}
          element
        </div>
      </v-card-title>
      <v-divider />
      <v-card-text class="pt-6 pb-3">
        <p v-if="element.description">
          {{ element.description }}
        </p>
        <p v-if="ec3DataSource.name">
          <strong>Name: </strong>
          {{ ec3DataSource.name }}
        </p>
        <p v-if="ec3DataSource.description">
          <strong>Description: </strong>
          <br>
          {{ ec3DataSource.description }}
        </p>
        <p v-if="ec3DataSource.declaration">
          <strong>Declaration: </strong>
          {{ ec3DataSource.declaration }}
        </p>
        <p v-if="ec3DataSource.manufacturer">
          <strong>Manufacturer: </strong>
          {{ ec3DataSource.manufacturer.name }}
        </p>
        <div v-if="element.category === 'Existing Trees Removal'">
          <p>
            Harvest Wood Product - Trees that are converted to wood products are able to lock up the carbon stored in their biomass for 50+ years, as opposed to it being released back to the atmosphere through decomposition.
          </p>
          <p>
            Mulch - When a tree is cut down and mulched, it begins to decompose. Over time, all the CO₂e stored in the tree biomass will be released to the atmosphere.
          </p>
          <p>
            Biochar - Removed trees can also be used as feedstock to produce biochar, which is a long-term store of carbon.
          </p>
        </div>
        <p
          v-if="element.category === 'paving'"
          class="info--text"
        >
          <v-icon
            class="mr-2"
            color="info"
            style="font-size: 20px; margin-bottom: 2px;"
          >
            mdi-information-outline
          </v-icon>Sub-base materials are not included.
        </p>
        <div v-if="element.customTemplateSourceType === 'ec3'">
          <p>
            <strong>EC3 Category: </strong>
            {{ ec3DataSource.ec3SelectedCategory }} / {{ ec3DataSource.ec3SelectedSubcategory }}
          </p>
        </div>
        <div v-if="co2FormulaInfo">
          <p>
            <strong>Impact type: </strong>
            {{ co2FormulaInfo.impactType }}
          </p>
          <p v-if="element.type === 'materials'">
            <strong>Expected replacements over lifespan: </strong>
            {{ element.replacements === 0 ? 'none' : element.replacements }}
          </p>
          <p v-if="average && (element.type !== 'maintenance' && element.type !=='hydrozone')">
            <strong v-if="element.type === 'plant'">Average annual sequestration</strong>
            <strong v-if="element.type === 'material'">Global Warming Potential (GWP)</strong>
            <br>
            <span
              :style="{ color: average > 0 ? '#dc0e15' : 'var(--v-shamrock-base)' }"
            >
              <strong>{{ Math.abs(average).toLocaleString() }} kgCO₂e per {{ element.co2MeasuredPer }}</strong>
            </span>
          </p>
          <p v-if="organicCarbonStored">
            <strong>Carbon Stored within Organic Material</strong>
            <br>
            <span>
              <strong>{{ Math.abs(organicCarbonStored).toLocaleString() }} kgCO₂e per {{ element.co2MeasuredPer }}</strong>
            </span>
            <br>Carbon Stored is calculated by 50% dry biomass (% Carbon), and multiplied by CO2e factor (3.66).  This does not affect material emissions.
          </p>
        </div>
        <div v-if="element.customTemplateSourceType === 'ec3'">
          <p v-if="ec3DataSource.selectedGWPPerUnit && ec3DataSource.co2MeasuredPer">
            <strong>Uncertainty Adjusted Global Warming Potential (uaGWP)</strong>
            <br>
            <span
              class="ml-2"
              :style="{ color: '#dc0e15'}"
            >
              <strong>
                <SmartValue
                  :value="ec3DataSource.selectedGWPPerUnit.gwp.value"
                />
                {{ ec3DataSource.selectedGWPPerUnit.gwp.unit.symbol }} per {{ ec3DataSource.co2MeasuredPer.symbol }}
              </strong>
            </span>
          </p>
          <p v-if="ec3DataSource.ec3ref">
            <strong>Source</strong>
            <br>
            <a
              :href="ec3DataSource.ec3ref"
              target="_blank"
            >
              Click here: Environmental Production Declaration (EPD)
            </a>
          </p>
          <p>
            Building Transparency - EC3
            <a
              href="https://buildingtransparency.org/ec3"
              target="_blank"
            >
              https://buildingtransparency.org/ec3
            </a>
          </p>
        </div>
        <div v-if="elementVariables.data && elementVariables.data.m_citations">
          <strong>Material Notes and References</strong>
          <br>{{ elementVariables.data.m_citations }}
        </div>
        <div v-if="elementVariables.data && elementVariables.data.n_citations">
          <strong>Nursery Citations</strong>
          <br>{{ elementVariables.data.n_citations }}
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import BrandLogo from '@/components/atoms/BrandLogo'
import SmartValue from '@/components/atoms/SmartValue.vue'

export default {
  name: 'ElementDescriptionV3',
  components: {
    BrandLogo,
    SmartValue
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    element: {
      type: Object,
      required: true
    },
    co2: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      co2FormulaInfo: null,
      organicCarbonStored: null,
      elementVariables: {}
    }
  },
  computed: {
    average () {
      return this.co2FormulaInfo && (this.co2FormulaInfo.sourceImpact || this.co2FormulaInfo.yearlyAverage)
    },
    ec3DataSource () {
      return {
        name: this.element?.customTemplateSourceBody?.source?.name,
        description: this.element?.customTemplateSourceBody?.source?.description,
        declaration: this.element?.customTemplateSourceBody?.source?.declaration,
        manufacturer: this.element?.customTemplateSourceBody?.source?.manufacturer,
        ec3SelectedCategory: this.element?.customTemplateSourceQuery?.ec3SelectedCategory,
        ec3SelectedSubcategory: this.element?.customTemplateSourceQuery?.ec3SelectedSubcategory,
        selectedGWPPerUnit: this.element?.customTemplateSourceBody?.actual?.selectedGWPPerUnit,
        co2MeasuredPer: this.element?.customTemplateSourceBody?.actual?.co2MeasuredPer,
        ec3ref: this.element?.customTemplateSourceBody?.source?.manufacturer &&
          `https://buildingtransparency.org/ec3/epds/${this.element?.customTemplateSourceBody?.source?.id}`
      }
    }
  },
  watch: {
    dialogVisible (val) {
      if (val) {
        this.getCo2FormulaInfo()
      }
    }
  },
  methods: {
    async getCo2FormulaInfo () {
      const impactTypeLookup = {
        formulaForOneTime: 'once, during construction',
        formulaForGivenYear: 'continuous over time',
        sinkFormula: 'varying over time (dependent on period of life)'
      }
      let impactType
      for (const key in impactTypeLookup) {
        if (this.element[key]) {
          impactType = impactTypeLookup[key]
          break
        }
      }
      const res = await this.$axios.get(`/elements/${this.element._id}/yearlyAverage`)
      const variables = await this.$axios.get(`/elements/${this.element._id}/variables`)

      let sourceImpact
      let yearlyAverage
      if (impactType === Object.values(impactTypeLookup)[0]) {
        sourceImpact = res.data.average
      } else {
        yearlyAverage = res.data.average
      }
      this.organicCarbonStored = res.data.organicCarbonStored
      this.elementVariables = variables
      this.co2FormulaInfo = { impactType, yearlyAverage, sourceImpact }
    }
  }
}
</script>
